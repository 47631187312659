import {
  type ButtonStyles,
  buttonVariants,
} from '@repo/ui/components/Button.js'
import { Icon, type IconName } from '@repo/ui/components/Icon.js'
import { Spinner } from '@repo/ui/components/Spinner.js'
import { cn } from '@repo/ui/lib/utils'
import { Link, useNavigation } from 'react-router'

import type { ComponentProps } from 'react'

interface LinkButtonProps
  extends ButtonStyles,
    Omit<ComponentProps<typeof Link>, 'color'> {
  disabled?: boolean
  loading?: boolean
  icon?: IconName
}

export const LinkButton = ({
  size,
  variant,
  disabled,
  className,
  icon,
  loading,
  ...props
}: LinkButtonProps) => {
  const navigation = useNavigation()

  const isLoading =
    loading !== undefined
      ? loading
      : navigation.state !== 'idle' &&
        navigation.location?.pathname === props.to

  return (
    <Link
      {...props}
      className={cn(
        'flex items-center justify-center gap-2',
        buttonVariants({
          variant,
          size,
        }),
        disabled && 'pointer-events-none opacity-50',
        className
      )}
    >
      {isLoading ? <Spinner /> : icon ? <Icon name={icon} /> : null}
      {props.children}
    </Link>
  )
}
